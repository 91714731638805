import React, { useEffect, useRef, useState } from "react";

import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { Checkbox, Form, notification, Spin } from "antd";


import classNames from "classnames";

import { ICONS } from "../../config/icons";

import { HOST } from "../../config/index";

import useTranslation from "../../hooks/useTranslation";

import REQUESTS from "../../api/requests";

import Package from "./Package";

import PaypalComponent from "./components/PaypalComponent";

import StripeComponent from "./components/StripeComponent";

import TEXT from "../../config/text";

import PATHS from "../../config/paths";

import { useSelector } from "react-redux";

import ValidateMacAddress from "../../components/ValidateMacAddress";

import ErrorModal from "../../components/ErrorModal";

import EmailModal from "./components/EmailModal";

import CMIPayment from "./CMIPayment";
import MyFatoorahPayment from "./MyFatoorahPayment";

import style from "./style/payment.module.scss";

export default function DirectActivation() {
  const translation = useTranslation();

  const { appInfo } = useSelector((store) => store.globalState);

  const [paymentSettings, setPaymentSettings] = useState(null);

  const [packages, setPackages] = useState([]);

  const [selectedPackage, setSelectedPackage] = useState(null);

  const [macIsValid, setMacIsValid] = useState(false);

  const [agreeWithAll, setAgreeWithAll] = useState(false);

  const [stripeButtonLoading, setStripeButtonLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const [scheduleType, setScheduleType] = useState("month");

  const [haveMonthly, setHaveMonthly] = useState(false);

  const [haveYearly, setHaveYearly] = useState(false);

  const [haveLifetime, setHaveLifetime] = useState(false);

  const [stripeSecretKey, setStripeSecretKey] = useState(null);

  const [device, setDevice] = useState(null);

  const [macValue, setMacValue] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [show, setShow] = useState(false);

  const [paymentType, setPaymentType] = useState(null);

  const [isRenderPayedButtons, setIsRenderPayedButtons] = useState(true);

  const navigate = useNavigate();

  const cmiPaymentRow = useRef(null);

  const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.activation?.[text]) {
      return translation?.pages?.activation?.[text];
    }

    return TEXT?.pages?.activation?.[text];
  };

  const payByPaypal = () => {
    navigate("/payment-success");
  };

  const payByStripe = () => {
    setStripeButtonLoading(true);
    const code = searchParams.get("code");

    if (selectedPackage && macValue) {
      let host = `${HOST}payment/stripe/checkout?type=device_subscription&package_id=${selectedPackage.id}&device_mac=${macValue}`;

      if (code) {
        host += `&referral_link_code=${code}`;
      }

      window.location.href = host;

      setStripeButtonLoading(false);

    } else {

      setStripeButtonLoading(false);

    }
  };

  // const payByPixi = (email) => {
  //   const code = searchParams.get("code");

  //   if (selectedPackage && macValue && email) {
  //     let host = `${HOST}payment/dlocal?type=device_subscription&package_id=${selectedPackage.id}&device_mac=${macValue}&email=${email}`

  //     if (code) {
  //       host += `&referral_link_code=${code}`;
  //     }
  //     window.location.href = host;
  //   }
  // }

  // const payByCoinbase = (email) => {
  //   const code = searchParams.get("code");
  //   if (selectedPackage && macValue && email) {

  //     let cancel_url = `${HOST}payment-error`;
  //     let success_url = `${HOST}payment-success`;

  //     let host = `${HOST}payment/coinbase?type=device_subscription&package_id=${selectedPackage.id}&device_mac=${macValue}&email=${email}&cancel_url=${cancel_url}&success_url=${success_url}`
  //     if (code) {
  //       host += `&referral_link_code=${code}`;
  //     }
  //     window.location.href = host;
  //   }
  // };

  const pay = (email) => {
    const code = searchParams.get("code");

    if (selectedPackage && macValue && email) {

      let cancel_url = `${HOST}payment-error`;
      let success_url = `${HOST}payment-success`;

      let host = "";

      if (paymentType === "dlocal") {
        host = `${HOST}payment/dlocal?type=device_subscription&package_id=${selectedPackage.id}&device_mac=${macValue}&email=${email}`
      } else if (paymentType === "coinbase") {
        host = `${HOST}payment/coinbase?type=device_subscription&package_id=${selectedPackage.id}&device_mac=${macValue}&email=${email}&cancel_url=${cancel_url}&success_url=${success_url}`
      } else if (paymentType === "parcelado-pay") {
        host = `${HOST}payment/parceladoUSA?type=device_subscription&package_id=${selectedPackage.id}&device_mac=${macValue}&email=${email}`
      } else if (paymentType === "tazapay") {
        host = `${HOST}payment/taza_pay?type=device_subscription&package_id=${selectedPackage.id}&device_mac=${macValue}&email=${email}`
      }
      if (code) {
        host += `& referral_link_code=${code} `;
      }

      if (host.length) {
        window.location.href = host;
      }
    }
  };

  const returnCustomId = () => {
    const code = searchParams.get("code");

    const customId = {
      t: "ds", //type
      pi: selectedPackage?.id, //package_id
      dm: macValue, //device_mac
    };

    if (code) {
      customId.rlc = code; // referral_link_code
    }

    return JSON.stringify(customId);
  };

  const selectPackage = (item) => {
    if (item.id === selectedPackage?.id) return;
    setIsRenderPayedButtons(false);
    // setSelectedPackage(null);

    setSelectedPackage(item);

  }

  const getPaymentSettings = () => {
    REQUESTS.PAYMENT_SETTINGS((data) => {
      setPaymentSettings(data);
    });
  };

  // when the page is loaded, we get the payment settings and the packages from the server. If the user comes from the Android device,
  // then the package is selected automatically. If the user comes from the referral link, then the package is selected automatically.
  const getPackages = () => {

    const callback = (message) => {

      setPackages(message.rows);

      for (let i = 0; i < message.rows.length; i++) {
        if (message.rows[i].schedule === "month") {
          setHaveMonthly(true);
        } else if (message.rows[i].schedule === "year") {
          setHaveYearly(true);
        } else if (message.rows[i].schedule === "lifetime") {
          setHaveLifetime(true);
        }
      }

      // when in the link there is a package_id parameter, then we select the package with this id
      // and if there is no such package, then we select the recommended package
      const packageId = searchParams.get("package_id");

      if (packageId) {

        const finded = message.rows.find((item) => item.id == packageId);

        if (finded) {

          setSelectedPackage(finded);

          switch (finded.schedule) {
            case "month":
              setScheduleType("month");
              break;
            case "year":
              setScheduleType("year");
              break;
            case "lifetime":
              setScheduleType("lifetime");
              break;
            default:
              break;
          }
          return;
        }
      }

      let recomended = message.rows.find((item) => item.recommended);

      if (recomended) {

        setSelectedPackage(recomended);

        switch (recomended.schedule) {
          case "month":
            setScheduleType("month");
            break;
          case "year":
            setScheduleType("year");
            break;
          case "lifetime":
            setScheduleType("lifetime");
            break;
          default:
            break;
        }

        return;
      }

      if (message.rows.length > 0) {

        setSelectedPackage(message.rows[0]);

        switch (message.rows[0].schedule) {
          case "month":
            setScheduleType("month");
            break;
          case "year":
            setScheduleType("year");
            break;
          case "lifetime":
            setScheduleType("lifetime");
            break;
          default:
            break;
        }

      }

    };

    const errorCallback = (error) => { };

    REQUESTS.GET_PACKAGES(callback, errorCallback);

  };

  const payWithCMI = (email, name) => {
    const code = searchParams.get("referral_link_code");

    const body = {
      email,
      name,
      mac: macValue,
      package_id: selectedPackage?.id,
      type: "device_subscription",
      // BillTocompany: appInfo.app_name,
      // BillToName: macValue,
      // clientId: paymentSettings?.cmi_client_id, // LIVE CLIENT ID paymentSettings?.cmi_client_id, TEST CLIENTID "600001220",
      // currency: "504",
      // hashAlgorithm: "ver3",
      // lang: "en",
      // rnd: `${ new Date().getTime() / 1000 } `,
      // storetype: "3d_pay_hosting",
      // TranType: "PreAuth",
    };

    if (code) {
      body.referral_link_code = code;
    }

    REQUESTS.PAY_WITH_CMI(
      body,
      (data) => {
        for (let key in data) {
          let input = document.createElement("input");
          input.type = "hidden";
          input.value = data[key];
          input.name = key;
          cmiPaymentRow.current.appendChild(input);
        }

        cmiPaymentRow.current.submit();
      },
      (err) => {
        notification.error({
          message: "Error",
          description: err.message,
        });
      }
    );
  };

  const getStripeSecretKey = () => {
    REQUESTS.GET_STRIPE_SECRET_KEY(
      (data) => {
        setStripeSecretKey(data);
      },
      (err) => { },
      selectedPackage.id,
      macValue //mac
    );
  };

  useEffect(() => {
    if (paymentSettings?.stripe_enabled && selectedPackage && macIsValid) {
      getStripeSecretKey();
    }
  }, [selectedPackage, macIsValid]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    getPaymentSettings();

    getPackages();

    if (searchParams.get("mac")) {
      setMacValue(searchParams.get("mac"));
    }

  }, []);

  useEffect(() => {
    if (device && device.payed) {
      setShow(true);
      setErrorMessage("This  device already has activated package");
    } else {
      setErrorMessage("");
    }
  }, [device]);

  useEffect(() => {

    if (packages.length === 0) return;

    const recomended = packages.find((item) => item.recommended);

    if (recomended && recomended.schedule === scheduleType) {

      setSelectedPackage(recomended);
    } else {

      const findedItem = packages.find(item => item.schedule === scheduleType);
      setSelectedPackage(findedItem);

    }

  }, [scheduleType])

  useEffect(() => {

    setIsRenderPayedButtons(true);

  }, [selectedPackage, scheduleType]);

  return (
    <>
      <Form
        autoComplete="off"
        name="activation"
        form={form}
        className="activation-form-style"
        layout="vertical"
      >
        <p className={style["text"]}>{handleText("choose_plan_text")}</p>

        <div className={style["types-container"]}>
          {haveMonthly && (
            <div
              className={classNames(style["type"], {
                [style["active"]]: scheduleType === "month",
              })}
              onClick={() => {
                setIsRenderPayedButtons(false);
                setScheduleType("month")
              }}
            >
              {handleText("monthly")}
            </div>
          )}

          {haveYearly && (
            <div
              className={classNames(style["type"], {
                [style["active"]]: scheduleType === "year",
              })}
              onClick={() => {
                setIsRenderPayedButtons(false);
                setScheduleType("year")
              }}
            >
              {handleText("yearly")}
            </div>
          )}

          {haveLifetime && (
            <div
              className={classNames(style["type"], {
                [style["active"]]: scheduleType === "lifetime",
              })}
              onClick={() => {
                setIsRenderPayedButtons(false);

                setScheduleType("lifetime")

              }}
            >
              {handleText("one_time")}
            </div>
          )}
        </div>
        <div className={style["packages-list"]}>
          {packages.map((item, index) => {
            return (
              <Package
                key={item.id}
                name={item.name}
                price={item.price}
                currency={paymentSettings?.currency}
                description={item.description}
                onClick={() => selectPackage(item)}
                active={item.id === selectedPackage?.id}
                time={item.schedule}
                type={scheduleType}
              />
            );
          })}
        </div>

        <div className={style["activation-form-bottom"]}>
          <ValidateMacAddress
            value={macValue}
            setMacIsValid={setMacIsValid}
            setDevice={setDevice}
            setValue={setMacValue}
          />
          {!device?.payed && (
            <>
              <div className="agree-with-all-checkbox">
                <Checkbox
                  checked={agreeWithAll}
                  onChange={(e) => setAgreeWithAll(e.target.checked)}
                >
                  <Link to={PATHS.PRIVACY} target="_blank">
                    {handleText("agree_with_all")}
                  </Link>
                </Checkbox>
              </div>
              <p style={{ width: 300, marginTop: 10, color: "#fff" }} >
                {handleText("agree_with_all_description")}
              </p>
            </>
          )}

          {paymentSettings && (
            <>
              {!paymentSettings?.stripe_enabled &&
                !paymentSettings?.paypal_enabled &&
                !paymentSettings?.cmi_enabled &&
                !paymentSettings.dlocal_enabled &&
                !paymentSettings.my_fatoorah_enabled &&
                !paymentSettings.parcelado_enabled &&
                !paymentSettings.ineco_enabled &&
                !paymentSettings.coinbase_enabled &&
                !paymentSettings.apple_pay_enabled &&
                !paymentSettings.amazon_enabled &&
                !paymentSettings.taza_pay_enabled
                ? (
                  <div className="stripe-paypal-desibled-text">
                    <p>{handleText("payments_methods_disabled")}</p>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {agreeWithAll && macIsValid && selectedPackage && paymentSettings && (
                      <div style={{ width: "70%", marginTop: 20 }}>
                        {paymentSettings.cmi_enabled && (
                          <CMIPayment
                            payWithCMI={payWithCMI}
                          />
                        )}

                        {paymentSettings.paypal_enabled && isRenderPayedButtons && (
                          <PaypalComponent
                            customId={returnCustomId()}
                            type={
                              selectedPackage?.schedule === "lifetime"
                                ? "order"
                                : "subscription"
                            }
                            clientId={paymentSettings?.paypal_client_id}
                            deviceId={device?.id}
                            currency={paymentSettings?.currency}
                            selectedPackage={selectedPackage}
                            onApprove={payByPaypal}
                            paymentSettings={paymentSettings}
                          />
                        )}

                        {paymentSettings.stripe_enabled &&
                          selectedPackage.schedule !== "lifetime" && (
                            <div
                              onClick={payByStripe}
                              loading={stripeButtonLoading}
                              className={style["stripe-subscribe-button"]}
                            >
                              {
                                translation?.pages?.activation?.stripe_button_text
                                  ? <span>{handleText("stripe_button_text")}</span> :
                                  <svg
                                    width="47"
                                    height="27"
                                    viewBox="0 0 64 27"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M64 13.8886C64 9.29494 61.7955 5.67025 57.5822 5.67025C53.351 5.67025 50.791 9.29506 50.791 13.8528C50.791 19.2538 53.8134 21.9813 58.151 21.9813C60.2666 21.9813 61.8666 21.4968 63.0755 20.815V17.2261C61.8667 17.8363 60.48 18.2131 58.72 18.2131C56.9955 18.2131 55.4666 17.603 55.2711 15.4856H63.9645C63.9645 15.2522 64 14.3192 64 13.8886ZM55.2178 12.1839C55.2178 10.1563 56.4445 9.31286 57.5645 9.31286C58.6489 9.31286 59.8045 10.1563 59.8045 12.1839H55.2178ZM43.929 5.67025C42.1866 5.67025 41.0666 6.49564 40.4445 7.06996L40.2132 5.95741H36.3022V26.8799L40.7466 25.929L40.7645 20.8508C41.4045 21.3174 42.3466 21.9813 43.9111 21.9813C47.0934 21.9813 49.9911 19.3974 49.9911 13.7092C49.9734 8.50538 47.04 5.67025 43.9289 5.67025H43.929ZM42.8623 18.0336C41.8134 18.0336 41.191 17.6569 40.7645 17.1903L40.7466 10.533C41.2089 10.0127 41.8489 9.65389 42.8623 9.65389C44.48 9.65389 45.6 11.4841 45.6 13.8347C45.6 16.2392 44.4977 18.0336 42.8623 18.0336ZM30.1866 4.61157L34.6489 3.6426V0L30.1866 0.951056V4.61145V4.61157ZM30.1866 5.97533H34.6489V21.6762H30.1866V5.97533ZM25.4045 7.30312L25.12 5.97533H21.28V21.6762H25.7245V11.0356C26.7733 9.65389 28.5511 9.90509 29.1022 10.1024V5.97533C28.5334 5.75996 26.4534 5.36518 25.4045 7.30312ZM16.5155 2.08152L12.1777 3.01454L12.16 17.3876C12.16 20.0435 14.1334 21.9992 16.7645 21.9992C18.2222 21.9992 19.2889 21.7301 19.8755 21.4071V17.7645C19.3067 17.9978 16.4977 18.8232 16.4977 16.1674V9.79734H19.8755V5.97533H16.4977L16.5155 2.08152ZM4.49775 10.533C4.49775 9.8333 5.06662 9.56406 6.009 9.56406C7.36 9.56406 9.06663 9.97688 10.4178 10.7126V6.49565C8.94225 5.90354 7.4845 5.67025 6.009 5.67025C2.4 5.67025 0 7.57236 0 10.7485C0 15.7009 6.7555 14.9114 6.7555 17.0468C6.7555 17.8721 6.0445 18.1413 5.04887 18.1413C3.57337 18.1413 1.68887 17.5312 0.1955 16.7058V20.9765C1.84888 21.6942 3.52 21.9992 5.04887 21.9992C8.74662 21.9992 11.2889 20.1511 11.2889 16.9391C11.2711 11.5918 4.49775 12.5428 4.49775 10.533Z"
                                      fill="#635BFF"
                                    />
                                  </svg>
                              }
                            </div>
                          )}

                        {paymentSettings.dlocal_enabled &&
                          <div
                            onClick={() => setPaymentType("dlocal")}
                            className={style["pixi-payment-button"]}
                          >
                            Pixi pay
                          </div>
                        }
                        {paymentSettings.coinbase_enabled &&
                          <div className={style['coinbase-button']}
                            onClick={() => setPaymentType("coinbase")}
                          >
                            {ICONS.COINBASE}
                            Pay with Coinbase
                          </div>
                        }

                        {
                          paymentSettings.my_fatoorah_enabled &&
                          < MyFatoorahPayment
                            mac={macValue}
                            package_id={selectedPackage?.id}
                          />
                        }

                        {
                          paymentSettings.parcelado_enabled &&
                          <div className={style['parcelado-usa']} onClick={() => setPaymentType("parcelado-pay")} >Pagar com pix</div>
                        }
                        {
                          paymentSettings.taza_pay_enabled &&
                          <div className={style['tazapay']} onClick={() => setPaymentType("tazapay")} >Tazapay</div>
                        }
                      </div>
                    )}
                  </div>
                )}
            </>
          )}
        </div>
      </Form>
      <ErrorModal
        visible={device && device.payed && show ? true : false}
        message={errorMessage}
        onClose={() => {
          setShow(false);
          setDevice(null);
          setMacValue("");
        }}
      />
      {
        agreeWithAll && macIsValid && selectedPackage &&
        <div className={style["stripe-component-container"]}>
          {agreeWithAll &&
            selectedPackage &&
            selectedPackage.schedule === "lifetime" &&
            paymentSettings &&
            paymentSettings.stripe_public_key &&
            paymentSettings.stripe_enabled &&
            stripeSecretKey && (
              <StripeComponent
                paymentSettings={paymentSettings}
                stripeSecretKey={stripeSecretKey}
              />
            )}
        </div>
      }
      <form
        ref={cmiPaymentRow}
        method={"post"}
        action={paymentSettings?.geteway_url}
      ></form>
      <EmailModal
        pay={pay}
        open={paymentType}
        onCancel={() => setPaymentType(null)}
      />
    </>
  );
}
